import { required, minLength, requiredIf } from 'vuelidate/lib/validators';
import { validNameIsSafe } from '../helpers/nameValidations';

export default {
  title: {
    required,
    minLength: minLength(2),
    validNameIsSafe,
  },
  description: {},
  color: {
    required,
  },
  showOnSidebar: {},
  ownerType: {
    required,
  },
  ownerId: {
    required: requiredIf(prop => prop.ownerType !== 'Account'),
  },
};
