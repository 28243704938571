/* eslint no-console: 0 */
/* eslint-env browser */
/* eslint-disable no-new */
/* Vue Core */

import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import emitter from 'tiny-emitter/instance';

// Global Components
import hljsVuePlugin from '@highlightjs/vue-plugin';
import MultiSelect from 'vue-multiselect';
import WootSwitch from 'components/ui/Switch.vue';
import WootWizard from 'components/ui/Wizard.vue';
import FloatingVue from 'floating-vue';
import WootUiKit from '../dashboard/components';
import App from '../dashboard/App.vue';
import i18n from '../dashboard/i18n';
import commonHelpers from '../dashboard/helper/commons';
import {
  getAlertAudio,
  initOnEvents,
} from '../shared/helpers/AudioNotificationHelper';
import { initFaviconSwitcher } from '../shared/helpers/faviconHelper';
import router, { initalizeRouter } from '../dashboard/routes';

import store from '../dashboard/store';
import constants from '../dashboard/constants';
import * as Sentry from '@sentry/vue';
import {
  verifyServiceWorkerExistence,
  registerSubscription,
} from 'dashboard/helper/pushHelper';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { domPurifyConfig } from '../shared/helpers/HTMLSanitizer';
import { createGmapVuePlugin } from '@gmap-vue/v3';
import Vue3EasyDataTable from 'vue3-easy-data-table';

const app = createApp(App);
const i18nInstance = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: i18n,
  warnHtmlMessage: false,
});

if (
  window.errorLoggingConfig &&
  window.errorLoggingConfig.tracingEnabled === 'true'
) {
  Sentry.init({
    app,
    dsn: window.errorLoggingConfig.tracingBaseUrlWithProtocol,
    denyUrls: [
      // Chrome extensions
      /^chrome:\/\//i,
      /chrome-extension:/i,
      /extensions\//i,

      // Locally saved copies
      /file:\/\//i,

      // Safari extensions.
      /safari-web-extension:/i,
      /safari-extension:/i,
    ],
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: parseFloat(window.errorLoggingConfig.tracingSampleRate),
    environment: window.errorLoggingConfig.environment ?? process.env.NODE_ENV,
    trackComponents: true,
  });
}
window.WOOT = { router, store };

app.use(router);
app.use(i18nInstance);
app.use(store);

app.use(FloatingVue);
app.use(hljsVuePlugin);
app.use(WootUiKit);

// // For Vue DOMPurify HTML
app.use(VueDOMPurifyHTML, domPurifyConfig);

app.use(
  createGmapVuePlugin({
    load: {
      key: window.chatwootConfig.googleMapsToken,
      libraries: 'places',
    },
    installComponents: true,
  })
);

// Global components registration
app.component('MultiSelect', MultiSelect);
app.component('WootSwitch', WootSwitch);
app.component('WootWizard', WootWizard);
app.component('FluentIcon', FluentIcon);
app.component('EasyDataTable', Vue3EasyDataTable);

// Mount the app
app.mount('#app');

// load common helpers into js
commonHelpers();

window.WootConstants = constants;
// window.axios = createAxios(axios);
window.bus = emitter;

initalizeRouter();

/**
 * Sets up event listeners to initialize the audio context upon a user gesture
 * and removes those listeners once the audio context is successfully initialized,
 * ensuring compliance with browser policies that restrict autoplay.
 */
const setupAudioListeners = () => {
  getAlertAudio().then(() =>
    initOnEvents.forEach(event => {
      document.removeEventListener(event, setupAudioListeners, false);
    })
  );
};

window.addEventListener('load', () => {
  verifyServiceWorkerExistence(registration =>
    registration.pushManager.getSubscription().then(subscription => {
      if (subscription) {
        registerSubscription();
      } else if (Notification.permission === 'granted') {
        // automatically enable push notifications if permissions were granted
        registerSubscription();
      }
    })
  );

  initOnEvents.forEach(e => {
    document.addEventListener(e, setupAudioListeners, false);
  });
  initFaviconSwitcher();
});
