<template>
  <div :class="replyBoxClass" class="reply-box">
    <woot-banner
      v-if="showSelfAssignBanner"
      :action-button-label="$t('CONVERSATION.ASSIGN_TO_ME')"
      :banner-message="$t('CONVERSATION.NOT_ASSIGNED_TO_YOU')"
      :has-action-button="true"
      color-scheme="secondary"
      @click="onClickSelfAssign"
    />
    <reply-top-panel
      :characters-remaining="charactersRemaining"
      :has-enable-agent-inject="hasEnableAgentInject"
      :is-message-length-reaching-threshold="isMessageLengthReachingThreshold"
      :mode="replyType"
      :popout-reply-box="popoutReplyBox"
      :set-reply-mode="setReplyMode"
      @click="$emit('click')"
    />
    <div v-if="hasAICopilotMessageTag" class="reply-box__top">
      <div>
        <copilot-popover
          :message-data="aiCopilotMessageToSend"
          :show-popover="showPopover"
        />
        <vue3-tags-input
          :class="{ 'vue-tags-private-reply': isOnPrivateNote }"
          :max-tags="1"
          :maxlength="0"
          :placeholder="''"
          :tags="tags"
          data-test-id="tags_input"
          @on-tags-changed="onTagsChanged"
        />
      </div>
    </div>
    <div v-else class="reply-box__top">
      <canned-response
        v-if="showCannedMenu && hasSlashCommand"
        v-click-away="hideCannedMenu"
        :search-key="cannedSearchKey"
        @click="replaceText"
      />
      <variable-list
        v-if="showVariablesMenu && hasDoubleCurlyCommand"
        v-click-away="hideVariables"
        :search-key="variableSearchKey"
        @click="handleVariableSelection"
      />
      <emoji-input
        v-if="showEmojiPicker"
        v-click-away="hideEmojiPicker"
        :on-click="emojiOnClick"
      />
      <woot-audio-recorder
        v-if="showAudioRecorderEditor"
        ref="audioRecorderInput"
        @state-recorder-progress-changed="onStateProgressRecorderChanged"
        @state-recorder-changed="onStateRecorderChanged"
        @finish-record="onFinishRecorder"
      />
      <resizable-text-area
        v-else-if="!showRichContentEditor"
        ref="messageInput"
        v-model="message"
        :data-test-id="'reply_message_textarea'"
        :min-height="4"
        :placeholder="messagePlaceHolder"
        class="input"
        @blur="onBlur"
        @focus="onFocus"
        @input="typedVariableCheck"
        @typing-off="onTypingOff"
        @typing-on="onTypingOn"
      />
      <woot-message-editor
        v-else
        v-model="message"
        :data-test-id="'reply_message_editor'"
        :enable-variables="true"
        :is-private="isOnPrivateNote"
        :min-height="4"
        :placeholder="messagePlaceHolder"
        :variables="messageVariables"
        class="input"
        @blur="onBlur"
        @focus="onFocus"
        @input="checkVariables"
        @typing-off="onTypingOff"
        @typing-on="onTypingOn"
        @toggle-user-mention="toggleUserMention"
        @toggle-canned-menu="toggleCannedMenu"
        @toggle-variables-menu="toggleVariablesMenu"
      />
    </div>
    <div v-if="hasAttachments" class="attachment-preview-box" @paste="onPaste">
      <attachment-preview
        :attachments="attachedFiles"
        :remove-attachment="removeAttachment"
      />
    </div>
    <reply-bottom-panel
      :enable-multiple-file-upload="true"
      :enable-rich-editor="false"
      :enter-to-send-enabled="enterToSendEnabled"
      :inbox="inbox"
      :is-format-mode="showRichContentEditor"
      :is-on-private-note="isOnPrivateNote"
      :is-on-reply-bot="isOnReplyBot"
      :is-recording-audio="isRecordingAudio"
      :is-send-disabled="isReplyButtonDisabled"
      :mode="replyType"
      :on-add-link="onAddLink"
      :on-file-upload="onFileUpload"
      :on-send="onSendReply"
      :recording-audio-duration-text="recordingAudioDurationText"
      :recording-audio-state="recordingAudioState"
      :send-button-text="replyButtonLabel"
      :set-format-mode="setFormatMode"
      :show-audio-recorder="showAudioRecorder"
      :show-emoji-picker="showEmojiPicker"
      :show-file-upload="showFileUpload"
      :toggle-audio-recorder="toggleAudioRecorder"
      :toggle-audio-recorder-play-pause="toggleAudioRecorderPlayPause"
      :toggle-emoji-picker="toggleEmojiPicker"
      @onSendFileRequest="sendFileRequest"
    />

    <confirmation-modal
      ref="confirmDialog"
      :description="undefinedVariableMessage"
      :title="$t('CONVERSATION.REPLYBOX.UNDEFINED_VARIABLES.TITLE')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue3-click-away';
import alertMixin from 'shared/mixins/alertMixin';
import assignAgentMixin from 'shared/mixins/assignAgentMixin';

import EmojiInput from 'shared/components/emoji/EmojiInput.vue';
import ResizableTextArea from 'shared/components/ResizableTextArea.vue';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview.vue';
import ReplyTopPanel from 'dashboard/components/widgets/WootWriter/ReplyTopPanel.vue';
import ReplyBottomPanel from 'dashboard/components/widgets/WootWriter/ReplyBottomPanel.vue';
import ConfirmationModal from 'components/widgets/modal/ConfirmationModal.vue';
import { REPLY_EDITOR_MODES } from 'dashboard/components/widgets/WootWriter/constants';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import WootAudioRecorder from 'dashboard/components/widgets/WootWriter/AudioRecorder.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import {
  MAXIMUM_FILE_UPLOAD_SIZE,
  MESSAGE_TYPE,
} from 'shared/constants/messages';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import {
  getMessageVariables,
  getUndefinedVariablesInMessage,
  replaceVariablesInMessage,
} from 'dashboard/helper/messageHelper';
import Vue3TagsInput from 'vue3-tags-input';

import { buildHotKeys } from 'shared/helpers/KeyboardHelpers';
import { MESSAGE_MAX_LENGTH } from 'shared/helpers/MessageTypeHelper';
import inboxMixin from 'shared/mixins/inboxMixin';
import uiSettingsMixin, {
  isEditorHotKeyEnabled,
} from 'dashboard/mixins/uiSettings';
import { DirectUpload } from 'activestorage';
import CannedResponse from '../CannedResponse.vue';
import CopilotPopover from '../CopilotPopover.vue';
import wootConstants from '../../../../constants';
import { isValidFileType } from '../helpers/validateFileType';
import VariableList from '../VariableList.vue';
import WootBanner from 'components/ui/Banner.vue';

export default {
  components: {
    WootBanner,
    ConfirmationModal,
    VariableList,
    EmojiInput,
    CannedResponse,
    ResizableTextArea,
    AttachmentPreview,
    ReplyTopPanel,
    ReplyBottomPanel,
    WootMessageEditor,
    WootAudioRecorder,
    Vue3TagsInput,
    CopilotPopover,
  },
  mixins: [
    clickaway,
    inboxMixin,
    uiSettingsMixin,
    alertMixin,
    messageFormatterMixin,
    assignAgentMixin,
  ],
  props: {
    popoutReplyBox: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'update:popoutReplyBox'],
  data() {
    return {
      currentChatId: null,
      message: '',
      isFocused: false,
      showEmojiPicker: false,
      showMentions: false,
      showCannedMenu: false,
      showVariablesMenu: false,
      attachedFiles: [],
      isRecordingAudio: false,
      recordingAudioState: '',
      recordingAudioDurationText: '',
      isUploading: false,
      replyType: REPLY_EDITOR_MODES.REPLY,
      cannedSearchKey: '',
      variableSearchKey: '',
      hasUserMention: false,
      hasSlashCommand: false,
      hasDoubleCurlyCommand: false,
      bccEmails: '',
      ccEmails: '',
      tag: '',
      tags: [],
      aiCopilotMessageToSend: undefined,
      aiCopilotTextMessageToSend: undefined,
      showPopover: false,
      undefinedVariableMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      messageSignature: 'getMessageSignature',
      currentUser: 'getCurrentUser',
      lastEmail: 'getLastEmailInSelectedChat',
      globalConfig: 'globalConfig/get',
      accountId: 'getCurrentAccountId',
    }),
    hasMessage() {
      return (
        this.message.trim() !== '' ||
        this.attachedFiles.length > 0 ||
        this.ccEmails.trim() !== '' ||
        this.bccEmails.trim() !== '' ||
        this.isRecordingAudio === true
      );
    },
    hasAICopilotMessageTag() {
      return this.tags?.length > 0;
    },
    hasAICopilotTextMessage() {
      return this.aiCopilotTextMessageToSend?.content !== undefined;
    },
    showRichContentEditor() {
      return this.isOnPrivateNote;
    },
    assignedAgent: {
      get() {
        return this.currentChat.meta.assignee;
      },
      set(agent) {
        const agentId = agent ? agent.id : 0;
        this.$store.dispatch('setCurrentChatAssignee', agent);
        this.assignAgent(this.currentChat.id, agentId);
      },
    },
    showSelfAssignBanner() {
      if (this.message !== '' && !this.isOnPrivateNote) {
        if (!this.assignedAgent) {
          return true;
        }
        if (this.assignedAgent.id !== this.currentUser.id) {
          return true;
        }
      }

      return false;
    },
    inboxId() {
      return this.currentChat.inbox_id;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](
        this.currentChat.meta.sender.id
      );
    },
    hasEnableAgentInject() {
      return this.currentChat?.custom_attributes?.enableAgentInject;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    messagePlaceHolder() {
      return this.isOnPrivateNote
        ? this.$t('CONVERSATION.FOOTER.PRIVATE_MSG_INPUT')
        : this.$t('CONVERSATION.FOOTER.MSG_INPUT');
    },
    isMessageLengthReachingThreshold() {
      return this.message?.length > this.maxLength - 50;
    },
    charactersRemaining() {
      return this.maxLength - this.message.length;
    },
    isReplyButtonDisabled() {
      if (this.hasAttachments || this.hasRecordedAudio) return false;
      if (this.hasAICopilotMessageTag) return false;

      return (
        this.isMessageEmpty ||
        this.message.length === 0 ||
        this.message.length > this.maxLength
      );
    },
    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },
    maxLength() {
      return MESSAGE_MAX_LENGTH.GENERAL;
    },
    showFileUpload() {
      return !this.isOnReplyBot;
    },
    replyButtonLabel() {
      let sendMessageText = '';

      if (this.isOnPrivateNote) {
        sendMessageText = this.$t('CONVERSATION.REPLYBOX.CREATE');
      }

      let keyLabel = isEditorHotKeyEnabled(this.uiSettings, 'cmd_enter')
        ? ' (⌘ + ↵)'
        : ' (↵)';

      if (this.editorMessageKey === 'none') {
        keyLabel = '';
      }

      sendMessageText = this.isOnReplyBot
        ? this.$t('CONVERSATION.REPLYBOX.SEND_AS_USER')
        : this.$t('CONVERSATION.REPLYBOX.SEND');

      return `${sendMessageText}${keyLabel}`;
    },
    replyBoxClass() {
      return {
        'is-private': this.isOnPrivateNote,
        'is-focused': this.isFocused || this.hasAttachments,
      };
    },
    hasAttachments() {
      return this.attachedFiles?.length;
    },
    hasRecordedAudio() {
      return (
        this.$refs.audioRecorderInput &&
        this.$refs.audioRecorderInput.hasAudio()
      );
    },
    showAudioRecorder() {
      return !this.isOnPrivateNote && this.showFileUpload;
    },
    showAudioRecorderEditor() {
      return this.showAudioRecorder && this.isRecordingAudio;
    },
    isOnPrivateNote() {
      return this.replyType === REPLY_EDITOR_MODES.NOTE;
    },
    isOnReplyBot() {
      return this.replyType === REPLY_EDITOR_MODES.REPLY_BOT;
    },
    isMessageEmpty() {
      if (!this.message) {
        return true;
      }
      return !this.message.trim().replace(/\n/g, '').length;
    },
    sendWithSignature() {
      const { send_with_signature: isEnabled } = this.uiSettings;
      return isEnabled;
    },
    messageVariables() {
      return getMessageVariables({
        conversation: this.currentChat,
        contact: this.contact,
      });
    },
    editorMessageKey() {
      return this.uiSettings?.editor_message_key;
    },
    commandPlusEnterToSendEnabled() {
      return this.editorMessageKey === 'cmd_enter';
    },
    enterToSendEnabled() {
      return this.editorMessageKey === 'enter';
    },
  },
  watch: {
    assignedAgent(newVal, oldVal) {
      if (
        newVal &&
        oldVal &&
        newVal.id &&
        oldVal.id &&
        newVal.id !== this.currentUser.id &&
        newVal.id !== oldVal.id &&
        this.hasMessage
      ) {
        this.clearInput();
      }
    },
    currentChat(conversation) {
      this.tags = [];

      this.loadDraft();

      if (this.isOnPrivateNote || this.currentChatId === conversation.id) {
        return;
      }

      if (conversation.custom_attributes.enableAgentInject) {
        this.replyType = REPLY_EDITOR_MODES.REPLY_BOT;
      } else {
        this.replyType = REPLY_EDITOR_MODES.REPLY;
      }

      this.currentChatId = conversation.id;

      this.setCCEmailFromLastChat();
    },
    message(updatedMessage) {
      if (!updatedMessage) {
        if (this.showCannedMenu) {
          this.showCannedMenu = false;
        }

        this.aiCopilotTextMessageToSend = '';
        this.doAutoSaveDraft();
        return;
      }

      this.hasSlashCommand =
        updatedMessage[0] === '/' && !this.showRichContentEditor;
      this.hasDoubleCurlyCommand =
        updatedMessage.includes('{{') && !this.showRichContentEditor;

      const isShortCodeActive = this.hasSlashCommand;
      const isVariableShortCodeActive =
        this.hasDoubleCurlyCommand && !updatedMessage.includes('}');

      if (isShortCodeActive) {
        const start = updatedMessage.indexOf('/');
        this.cannedSearchKey = updatedMessage.slice(start + 1);
        this.showCannedMenu = true;
      } else {
        this.cannedSearchKey = '';
        this.showCannedMenu = false;
      }

      if (isVariableShortCodeActive) {
        const start = updatedMessage.indexOf('{{');
        this.variableSearchKey = updatedMessage.slice(start + 2);
        this.showVariablesMenu = true;
      } else {
        this.variableSearchKey = '';
        this.showVariablesMenu = false;
      }

      this.doAutoSaveDraft();
      this.doDraftMessageCleanup();
    },
    tags: {
      handler() {
        const inputTag = document.getElementsByClassName('v3ti-tag-content');
        this.$nextTick(() => {
          if (inputTag[0]) {
            inputTag[0].addEventListener('mouseenter', () => {
              this.onTagMouseEnter();
            });
            inputTag[0].addEventListener('mouseleave', () => {
              this.onTagMouseLeave();
            });
          }
        });
        if (this.tags.length <= 0) {
          this.showPopover = false;
        }
      },
      deep: true,
    },
    isOnPrivateNote() {
      this.loadDraft();
      this.tags = [];
    },
  },

  mounted() {
    this.doDraftMessageCleanup();
    this.loadDraft();
    // Do not use the keyboard listener mixin here as the events here are supposed to be
    // working even if input/textarea is focussed.
    document.addEventListener('keydown', this.handleKeyEvents);
    document.addEventListener('paste', this.onPaste);

    window.addEventListener('message', this.handlePostMessage);

    this.setCCEmailFromLastChat();

    this.doAutoSaveDraft();
  },
  unmounted() {
    document.removeEventListener('keydown', this.handleKeyEvents);
    document.removeEventListener('paste', this.onPaste);
    window.removeEventListener('message', this.handlePostMessage);
    this.removeFromDraft();
  },
  updated() {
    this.doDraftMessageCleanup();
    this.loadDraft();
  },
  methods: {
    doAutoSaveDraft() {
      if (!this.isDraftable()) {
        if (this.message !== '') {
          this.removeFromDraft();
        } else {
          this.message = '';
        }
      } else {
        this.saveDraft(this.currentChat.id, this.replyType);
      }
    },
    doDraftMessageCleanup() {
      this.$store.dispatch('draftStore/clearDraftsByTTL');
    },
    isDraftable() {
      if (this.replyType === REPLY_EDITOR_MODES.NOTE) {
        return true;
      }

      return (
        this.currentChat.status !== 'resolved' &&
        this.currentChat.status !== 'abandoned' &&
        this.currentChat.status !== 'deleted'
      );
    },
    loadDraft() {
      if (this.currentChat && this.isDraftable()) {
        const draft = this.$store.getters['draftStore/get'](
          this.currentChat.id,
          this.replyType
        );
        if (draft) {
          this.message = draft.content;
        } else {
          this.message = '';
        }
      } else {
        this.message = '';
      }
    },
    saveDraft(id, replyType) {
      this.$store.dispatch('draftStore/set', {
        id: id,
        replyType: replyType,
        message: this.message,
      });
    },
    removeFromDraft() {
      this.$store.dispatch('draftStore/delete', {
        id: this.currentChat.id,
        replyType: this.replyType,
      });
    },
    handleKeyEvents(e) {
      const keyCode = buildHotKeys(e);
      if (keyCode === 'escape') {
        this.hideEmojiPicker();
        this.hideMentions();
      } else if (keyCode === 'meta+k') {
        const ninja = document.querySelector('ninja-keys');
        ninja.open();
        e.preventDefault();
      } else if (keyCode === 'enter' && this.isAValidEvent('enter')) {
        this.onSendReply();
        e.preventDefault();
      } else if (
        ['meta+enter', 'ctrl+enter'].includes(keyCode) &&
        this.isAValidEvent('cmd_enter')
      ) {
        this.onSendReply();
      }
    },
    isAValidEvent(selectedKey) {
      return (
        !this.hasUserMention &&
        !this.showCannedMenu &&
        this.isFocused &&
        isEditorHotKeyEnabled(this.uiSettings, selectedKey)
      );
    },
    onPaste(e) {
      const data = e.clipboardData.files;
      if (!this.showRichContentEditor && data.length !== 0) {
        this.$refs.messageInput.$el.blur();
      }
      if (data && (Array.isArray(data) || typeof data === 'object')) {
        (Array.isArray(data) ? data : Object.values(data)).forEach(file => {
          const { name, type, size } = file;
          this.onFileUpload({ name, type, size, file: file });
        });
      }
    },
    toggleUserMention(currentMentionState) {
      this.hasUserMention = currentMentionState;
    },
    toggleCannedMenu(value) {
      this.showCannedMenu = value;
    },
    toggleVariablesMenu(value) {
      this.showVariablesMenu = value;
    },
    onTagsChanged(newTags) {
      if (newTags.length > 0) {
        this.tags = newTags.map(el => el.text);
      } else {
        this.tags = [];
        this.clearInput();
        this.showPopover = false;
        this.$nextTick(() => this.$refs.messageInput.focus());
      }
    },
    clearInput() {
      this.clearMessage();
      this.aiCopilotMessageToSend = undefined;
      this.aiCopilotTextMessageToSend = undefined;
    },
    onClickSelfAssign() {
      const {
        account_id,
        availability_status,
        available_name,
        email,
        id,
        name,
        role,
        avatar_url,
      } = this.currentUser;
      this.assignedAgent = {
        account_id,
        availability_status,
        available_name,
        email,
        id,
        name,
        role,
        thumbnail: avatar_url,
      };
    },
    async confirmOnSendReply() {
      if (this.isReplyButtonDisabled) {
        return;
      }

      if (this.aiCopilotMessageToSend !== undefined) {
        await this.sendAICopilotMessage(this.aiCopilotMessageToSend);
        this.clearInput();
        return;
      }

      if (this.hasAICopilotTextMessage) {
        // Change the content to the current message in case the message was edited by the agent
        const messageToSend = {
          ...this.aiCopilotTextMessageToSend,
          content: this.message,
        };

        await this.sendAICopilotTextMessageWithPayload(messageToSend);
        this.clearInput();
        return;
      }

      if (!this.showMentions) {
        let newMessage = this.message;

        if (this.isSignatureEnabledForInbox && this.messageSignature) {
          newMessage += '\n\n' + this.messageSignature;
        }

        const messagePayload = this.getMessagePayload(newMessage);
        this.clearInput();

        if (this.isOnReplyBot) {
          messagePayload.message_type = MESSAGE_TYPE.ON_BEHALF_OF_USER;
        }

        await this.dispatchCallbackAndScroll(async () => {
          await this.$store.dispatch(
            'createPendingMessageAndSend',
            messagePayload
          );
        });

        this.removeFromDraft();

        this.$emit('update:popoutReplyBox', false);
      }
    },
    async onSendReply() {
      const undefinedVariables = getUndefinedVariablesInMessage({
        message: this.message,
        variables: this.messageVariables,
      });

      if (undefinedVariables.length > 0) {
        const undefinedVariablesCount = undefinedVariables.length;
        this.undefinedVariableMessage = this.$t(
          'CONVERSATION.REPLYBOX.UNDEFINED_VARIABLES.MESSAGE',
          {
            undefinedVariablesCount,
            undefinedVariables: undefinedVariables.join(', ').trim(),
          }
        );

        const confirm = await this.$refs.confirmDialog.showConfirmation();
        if (confirm) {
          await this.confirmOnSendReply();
        }
      } else {
        await this.confirmOnSendReply();
      }

      this.removeFromDraft();
    },
    async sendFileRequest() {
      const newMessage = '';
      const newActivityMessage = this.$t(
        'CONVERSATION.REPLYBOX.FILE_REQUESTED'
      );
      const messagePayload = this.getMessagePayload(newMessage);
      const messageActivityPayload = this.getMessagePayload(newActivityMessage);

      messagePayload.inboxIdentifier = this.inbox.inbox_identifier;

      await this.dispatchCallbackAndScroll(async () => {
        await this.$store.dispatch('sendFileRequest', messagePayload);
        await this.$store.dispatch(
          'sendFileRequestActivity',
          messageActivityPayload
        );
      });
    },
    async sendAICopilotMessage(message) {
      this.tags = [];

      const messagePayload = this.getMessagePayload(message);

      await this.dispatchCallbackAndScroll(async () => {
        await this.$store.dispatch(
          'createPendingAICopilotMessageAndSend',
          messagePayload
        );
      });

      this.removeFromDraft();

      this.$emit('update:popoutReplyBox', false);
    },
    async sendAICopilotTextMessageWithPayload(message) {
      this.message = '';
      this.aiCopilotTextMessageToSend = '';

      const messagePayload = this.getMessagePayload(message);

      await this.dispatchCallbackAndScroll(async () => {
        await this.$store.dispatch(
          'createPendingAICopilotTextMessageAndSend',
          messagePayload
        );
      });

      this.removeFromDraft();

      this.$emit('update:popoutReplyBox', false);
    },
    async dispatchCallbackAndScroll(callback) {
      try {
        await callback();

        bus.emit(BUS_EVENTS.SCROLL_TO_MESSAGE);
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || this.$t('CONVERSATION.MESSAGE_ERROR');
        this.showAlert(errorMessage, {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }

      this.hideEmojiPicker();
    },
    onAddLink({ name, url }) {
      const link = `<a href="${url}" target="_blank">${name}</a>`;

      this.message = `${this.message}${link}`;

      this.$nextTick(() => this.$refs.messageInput.focus());
    },
    replaceText(message) {
      const updatedMessage = replaceVariablesInMessage({
        message,
        variables: this.messageVariables,
      });
      setTimeout(() => {
        this.message = updatedMessage;
      }, 100);
    },

    async handleVariableSelection(variable) {
      let updatedMessage = this.message;
      const insertStart = updatedMessage.lastIndexOf('{{');
      const slice = updatedMessage.slice(0, insertStart + 2);
      updatedMessage = `${slice}${variable}}} `;

      await this.checkVariables(updatedMessage);
    },

    async typedVariableCheck() {
      const updatedMessage = this.message;
      await this.checkVariables(updatedMessage);
    },

    addVariableToText(message) {
      this.message = replaceVariablesInMessage({
        message: message,
        variables: this.messageVariables,
      });
    },

    async checkVariables(message) {
      // check if variable input is undefined:
      const undefinedVariables = getUndefinedVariablesInMessage({
        message: message,
        variables: this.messageVariables,
      });

      if (undefinedVariables.length > 0) {
        this.$refs.messageInput.$el.blur();

        this.undefinedVariableMessage = this.$t(
          'CONVERSATION.REPLYBOX.UNDEFINED_VARIABLES.MESSAGE',
          {
            undefinedVariablesCount: undefinedVariables.length,
            undefinedVariables: undefinedVariables.join(', ').trim(),
          }
        );

        const confirm = await this.$refs.confirmDialog.showConfirmation();
        if (confirm) {
          this.undefinedVariableMessage = '';

          this.addVariableToText(message);
        }
      } else {
        this.undefinedVariableMessage = '';

        this.addVariableToText(message);
      }
    },

    setReplyMode(mode = REPLY_EDITOR_MODES.REPLY) {
      this.$store.dispatch('draftStore/setReplyEditorMode', {
        mode,
      });

      this.replyType = mode;

      if (this.showRichContentEditor) {
        if (this.isRecordingAudio) {
          this.toggleAudioRecorder();
        }
        return;
      }

      this.$nextTick(() => this.$refs.messageInput.focus());
    },
    emojiOnClick(emoji) {
      this.message = `${this.message}${emoji} `;
    },
    clearMessage() {
      this.message = '';
      this.attachedFiles = [];
      this.ccEmails = '';
      this.bccEmails = '';
      this.isRecordingAudio = false;
      this.removeFromDraft();
    },
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    toggleAudioRecorder() {
      this.isRecordingAudio = !this.isRecordingAudio;
      this.isRecorderAudioStopped = !this.isRecordingAudio;
      if (!this.isRecordingAudio) {
        this.clearMessage();
      }
    },
    toggleAudioRecorderPlayPause() {
      if (this.isRecordingAudio) {
        if (!this.isRecorderAudioStopped) {
          this.isRecorderAudioStopped = true;
          this.$refs.audioRecorderInput.stopAudioRecording();
        } else if (this.isRecorderAudioStopped) {
          this.$refs.audioRecorderInput.playPause();
        }
      }
    },
    hideEmojiPicker() {
      if (this.showEmojiPicker) {
        this.toggleEmojiPicker();
      }
    },
    hideMentions() {
      this.showMentions = false;
    },
    hideVariables() {
      this.showVariablesMenu = false;
    },
    hideCannedMenu() {
      this.showCannedMenu = false;
    },
    onTypingOn() {
      this.toggleTyping('on');
    },
    onTypingOff() {
      this.toggleTyping('off');
    },
    onBlur() {
      this.isFocused = false;
      this.saveDraft(this.currentChat.id, this.replyType);
    },
    onFocus() {
      this.isFocused = true;
    },
    onStateProgressRecorderChanged(duration) {
      this.recordingAudioDurationText = duration;
    },
    onStateRecorderChanged(state) {
      this.recordingAudioState = state;
      if (state && 'notallowederror'.includes(state)) {
        this.toggleAudioRecorder();
      }
    },
    onFinishRecorder(file) {
      return file && this.onFileUpload(file);
    },
    toggleTyping(status) {
      const conversationId = this.currentChat.original_id;
      const isPrivate = this.isOnPrivateNote;
      this.$store.dispatch('conversationTypingStatus/toggleTyping', {
        status,
        conversationId,
        isPrivate,
      });
    },
    onFileUpload(file) {
      const fileIsValid = isValidFileType(file);
      if (!fileIsValid) {
        this.showAlert(this.$t('CONVERSATION.UNSUPORTED_FILE_TYPE'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
        return;
      }
      if (this.globalConfig.directUploadsEnabled) {
        this.onDirectFileUpload(file);
      } else {
        this.onIndirectFileUpload(file);
      }
    },
    onTagMouseEnter() {
      this.showPopover = true;
    },
    onTagMouseLeave() {
      this.showPopover = false;
    },
    onDirectFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        const upload = new DirectUpload(
          file.file,
          `/api/v1/accounts/${this.accountId}/conversations/${this.currentChat.id}/direct_uploads`,
          {
            directUploadWillCreateBlobWithXHR: xhr => {
              xhr.setRequestHeader(
                'api_access_token',
                this.currentUser.access_token
              );
            },
          }
        );

        upload.create((error, blob) => {
          if (error) {
            this.showAlert(error, {
              status: wootConstants.SNACKBAR_TYPE.ERROR,
            });
          } else {
            this.attachFile({ file, blob });
          }
        });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          }),
          {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          }
        );
      }
    },
    onIndirectFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachFile({ file });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          }),
          {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          }
        );
      }
    },
    attachFile({ blob, file }) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          currentChatId: this.currentChat.id,
          resource: blob || file,
          isPrivate: this.isOnPrivateNote,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    getMessagePayload(message) {
      const messagePayload = {
        conversationId: this.currentChat.id,
        message,
        private: this.isOnPrivateNote,
      };
      if (this.inReplyTo) {
        messagePayload.contentAttributes = {
          in_reply_to: this.inReplyTo,
        };
      }

      if (this.attachedFiles && this.attachedFiles.length) {
        messagePayload.files = [];
        this.attachedFiles.forEach(attachment => {
          if (this.globalConfig.directUploadsEnabled) {
            messagePayload.files.push(attachment.blobSignedId);
          } else {
            messagePayload.files.push(attachment.resource.file);
          }
        });
      }

      if (this.ccEmails && !this.isOnPrivateNote) {
        messagePayload.ccEmails = this.ccEmails;
      }

      if (this.bccEmails && !this.isOnPrivateNote) {
        messagePayload.bccEmails = this.bccEmails;
      }
      return messagePayload;
    },
    setFormatMode(value) {
      this.updateUISettings({ display_rich_content_editor: value });
    },
    setAssistMessageInReplyBox(message) {
      this.setReplyBoxMessage(message);
    },
    setStructuredAssistMessageInReplyBox(message, displayText) {
      this.setReplyBoxMessage(displayText, () => {
        this.aiCopilotMessageToSend = message;
      });
    },
    setAssistTextMessageInReplyBox(message) {
      this.setReplyBoxMessage(message.content, () => {
        this.aiCopilotTextMessageToSend = message;
      });
    },
    setReplyBoxMessage(messageText, callback = null) {
      this.clearInput();
      this.message = messageText;

      if (typeof callback === 'function') {
        callback();
      }

      // there are certain cases (for instance where the message input is already focused) where this.$refs.messageInput is undefined. Hence this check
      if (this.$refs?.messageInput) {
        this.$refs.messageInput.focus();
      }
    },
    appendMessageToReplyBox(message) {
      if (message?.trim() === '') {
        return;
      }

      this.message = `${this.message} ${message}`;

      this.$refs.messageInput.focus();
    },
    setCCEmailFromLastChat() {
      if (this.lastEmail) {
        const {
          content_attributes: { email: emailAttributes = {} },
        } = this.lastEmail;
        const cc = emailAttributes.cc || [];
        const bcc = emailAttributes.bcc || [];
        this.ccEmails = cc.join(', ');
        this.bccEmails = bcc.join(', ');
      }
    },
    setAssistMessageTagInReplyBox(message, tag) {
      this.clearInput();
      this.aiCopilotMessageToSend = message;
      this.tags = [tag];
      // Wait for the input element to be rendered
      this.$nextTick(() => {
        // add focus to the input element
        document.getElementsByClassName('v3ti-new-tag')[0].focus();

        // add listener to listen the "enter key" for sending the message
        document
          .getElementsByClassName('v3ti-new-tag')[0]
          .addEventListener('keydown', e => {
            if (e.key === 'Enter') {
              this.sendAICopilotMessage(this.aiCopilotMessageToSend);
              this.clearInput();
            }
          });
      });
    },
    handlePostMessage(event) {
      if (event.data?.type === 'replyBoxContent') {
        this.appendMessageToReplyBox(event.data.content);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.send-button {
  margin-bottom: 0;
}

.message-signature-wrap {
  margin: 0 var(--space-normal);
  padding: var(--space-small);
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border: 1px dashed var(--s-100);
  border-radius: var(--border-radius-small);
  max-height: 8vh;
  overflow: auto;

  &:hover {
    background: var(--s-25);
  }
}

.message-signature {
  width: fit-content;
  margin: 0;
}

.attachment-preview-box {
  padding: 0 var(--space-normal);
  background: transparent;
}

.reply-box {
  border-top: 1px solid var(--color-border);
  background: white;

  &.is-private {
    background: var(--y-50);
  }
}

.send-button {
  margin-bottom: 0;
}

.reply-box__top {
  padding: 0 var(--space-normal);
  border-top: 1px solid var(--color-border);
  margin-top: -1px;
}

.emoji-dialog {
  top: unset;
  bottom: 12px;
  left: -320px;
  right: unset;

  &::before {
    right: -16px;
    bottom: 10px;
    transform: rotate(270deg);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  }
}

.message-signature {
  margin-bottom: 0;

  :deep(p:last-child) {
    margin-bottom: 0;
  }
}

.v3ti {
  max-width: none;
  height: 48px;
}
</style>
<style lang="scss">
.v3ti .v3ti-tag {
  background: #154194 !important;
  color: #ffffff;
  margin-right: 4px !important;
  border-radius: 6px !important;
  font-size: 13px !important;
  padding: 10px !important;
  margin-top: 10px !important;
  height: 35px !important;
}

.v3ti-remove-tag {
  opacity: 1 !important;
  margin-top: -3px;
}

.v3ti,
.v3ti--focus {
  border: none !important;
  box-shadow: none !important;
}

.vue-tags-private-reply {
  background-color: #fff9eb !important;

  .ti-new-tag-input {
    background-color: #fff9eb;
  }
}
</style>
