import { MESSAGE_TYPE } from 'shared/constants/messages';
import { IFrameHelper } from 'widget/helpers/utils';
import { showBadgeOnFavicon } from './faviconHelper';

export const initOnEvents = ['click', 'touchstart', 'keypress', 'keydown'];

const getGlobalSettings = () => {
  const {
    router: {
      currentRoute: {
        value: {
          params: { conversation_id: currentConversationId },
        },
      },
    },
    store: {
      getters: {
        getCurrentUserID: currentUserId,
        getConversationById,
        getUISettings: { enable_audio_alerts: enableAudioAlerts = false },
        getCurrentAccount: { id: currentAccountId },
        'accounts/getAccount': getAccount,
      },
    },
  } = window.WOOT;

  return {
    currentConversationId,
    currentUserId,
    enableAudioAlerts,
    getConversationById,
    currentAccountId,
    getAccount,
  };
};

const fetchAndPlayAudio = async (audioCtx, soundUrl) => {
  try {
    const response = await fetch(soundUrl);
    const buffer = await response.arrayBuffer();
    const audioBuffer = await audioCtx.decodeAudioData(buffer);

    const source = audioCtx.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioCtx.destination);
    source.loop = false;
    source.start();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error playing audio:', error);
  }
};

// Initialize the play audio function
export const getAlertAudio = async (baseUrl = '', type = 'dashboard') => {
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

  window.playAudioAlert = async () => {
    const { currentAccountId, getAccount } = getGlobalSettings();
    const account = getAccount(currentAccountId);
    if (account) {
      const {
        custom_attributes: { audio_file_url, notification_sound_type },
      } = getAccount(currentAccountId);
      const soundUrl =
        notification_sound_type === 'custom'
          ? audio_file_url
          : `${baseUrl}/audio/${type}/ding.mp3`;

      await fetchAndPlayAudio(audioCtx, soundUrl);
    } else {
      // eslint-disable-next-line no-console
      console.warn('Audio alert could not be played due to missing account');
    }
  };
};

export const notificationEnabled = (enableAudioAlerts, userId, assigneeId) =>
  (enableAudioAlerts === 'mine' && userId === assigneeId) ||
  enableAudioAlerts === 'all';

export const shouldPlayAudio = (
  message,
  conversationId,
  userId,
  isDocHidden
) => {
  const {
    conversation_id,
    sender_id,
    sender_type,
    message_type,
    private: isPrivate,
  } = message;

  const isFromCurrentUser = userId === sender_id && sender_type === 'User';

  const isIncomingOrPrivate =
    message_type === MESSAGE_TYPE.INCOMING || isPrivate;

  return (
    !isFromCurrentUser &&
    isIncomingOrPrivate &&
    (isDocHidden || Number(conversationId) !== conversation_id)
  );
};

export const getAssigneeFromConversation = conversation => {
  const { meta } = conversation;
  return meta && meta.assignee ? meta.assignee.id : undefined;
};

export const playNewMessageNotificationInWidget = () => {
  IFrameHelper.sendMessage({
    event: 'playAudio',
  });
};
export const newMessageNotification = message => {
  const {
    currentConversationId,
    currentUserId,
    enableAudioAlerts,
    getConversationById,
  } = getGlobalSettings();
  const { hidden } = document;
  const { conversation_id: incomingConversationId } = message;

  const targetConversation = getConversationById(incomingConversationId) || {};
  const { muted } = targetConversation;
  const assigneeId = getAssigneeFromConversation(targetConversation);

  const playSound =
    notificationEnabled(enableAudioAlerts, currentUserId, assigneeId) &&
    shouldPlayAudio(message, currentConversationId, currentUserId, hidden) &&
    !muted;

  if (playSound) {
    showBadgeOnFavicon();
    window.playAudioAlert();
  }
};

export const newAssigneeNotification = conversation => {
  const { currentUserId, enableAudioAlerts, currentConversationId } =
    getGlobalSettings();
  const assigneeId = getAssigneeFromConversation(conversation);
  const { muted, id } = conversation;
  const playSound =
    notificationEnabled(enableAudioAlerts, currentUserId, assigneeId) &&
    currentUserId === assigneeId &&
    !muted &&
    (document.hidden || Number(currentConversationId) !== id);

  if (playSound) {
    showBadgeOnFavicon();
    window.playAudioAlert();
  }
};
