export default {
  methods: {
    notificationTitle(notification) {
      if (notification.primary_actor_type === 'Conversation') {
        return notification.primary_actor_display_id;
      }

      if (notification.secondary_actor_type === 'Conversation') {
        return notification.secondary_actor_display_id;
      }

      return this.$t(`NOTIFICATIONS_PAGE.DELETE_TITLE`);
    },
  },
};
