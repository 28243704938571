import {
  newMessageNotification,
  newAssigneeNotification,
} from 'shared/helpers/AudioNotificationHelper';
import AuthAPI from '../api/auth';

const typingCallbacks = new Map();

// Helper
/// ///////////////////////////
/**
 * Clears the typing indicator timer for a given conversation by removing the associated timeout and callback.
 *
 * @function clearTimer
 * @param {number|string} conversationId - The unique identifier of the conversation for which the timer should be cleared.
 * @returns {void}
 */
function clearTimer(conversationId) {
  if (typingCallbacks.has(conversationId)) {
    clearTimeout(typingCallbacks.get(conversationId));
    typingCallbacks.delete(conversationId);
  }
}

/**
 * Initializes a typing indicator timer for a given conversation, which will automatically turn off after 30 seconds.
 *
 * @function initTimer
 * @param {Object} params - Parameters containing conversation and user details.
 * @param {Object} params.conversation - The conversation object.
 * @param {number|string} params.conversation.id - The unique identifier of the conversation.
 * @param {Object} params.user - The user object.
 * @returns {void}
 */
function initTimer({ conversation, user }) {
  // Turn off typing automatically after 3 seconds
  typingCallbacks.set(
    conversation.id,
    setTimeout(() => {
      // eslint-disable-next-line no-use-before-define
      typingOff({ conversation, user });
    }, 30000)
  );
}

/**
 * Triggers an event to fetch the latest statistics for conversations.
 *
 * @function fetchConversationStats
 * @throws {Error} This function will break if the `bus` object is not defined or does not have an `$emit` method.
 * @returns {void}
 */
function fetchConversationStats() {
  bus.emit('fetch_conversation_stats');
}

// Conversation Handler
/// ////////////////////////////
/**
 * Handles the creation of a new message by dispatching relevant Vuex actions.
 *
 * @function messageCreated
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the new message.
 * @param {Object} data.conversation - The conversation object related to the message.
 * @param {string} data.conversation.last_activity_at - The timestamp of the last activity in the conversation.
 * @param {number|string} data.conversation_id - The unique identifier of the conversation.
 * @param {...any} data - Additional data related to the message.
 * @returns {void}
 */
function messageCreated(store, data) {
  newMessageNotification(data);
  store.dispatch('addMessage', data);
  store
    .dispatch('updateConversationLastActivity', {
      lastActivityAt: data.conversation.last_activity_at,
      conversationId: data.conversation_id,
    })
    .then(r => r);
}

/**
 * Handles the update of a message by dispatching relevant Vuex actions.
 *
 * @function messageUpdated
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the message.
 * @param {...any} data - Additional data related to the message.
 * @returns {void}
 */
const messageUpdated = (store, data) => {
  store.dispatch('updateMessage', data).then(r => r);
};

/**
 * Handles changes to the assignee of a conversation by dispatching relevant Vuex actions and fetching updated statistics.
 *
 * @function conversationAssigneeChanged
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the message.
 * @param {...any} data - Additional data related to the message.
 * @returns {void}
 */
function conversationAssigneeChanged(store, data) {
  if (data.id) {
    store.dispatch('updateConversation', data).then(r => r);
    newAssigneeNotification(data);
  }
  fetchConversationStats();
}

/**
 * Handles a new conversation by dispatching relevant Vuex actions and fetching updated statistics.
 *
 * @function conversationCreated
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the message.
 * @param {...any} data - Additional data related to the message.
 * @returns {void}
 */
function conversationCreated(store, data) {
  store.dispatch('addConversation', data).then(r => r);
  fetchConversationStats();
}

/**
 * Handles an conversation read by dispatching relevant Vuex actions.
 *
 * @function conversationRead
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the message.
 * @param {...any} data - Additional data related to the message.
 * @returns {void}
 */
function conversationRead(store, data) {
  const { contact_last_seen_at: lastSeen } = data;
  store.dispatch('updateConversationRead', lastSeen).then(r => r);
}

/**
 * Handles a status change on an conversation by dispatching relevant Vuex actions and fetching updated statistics.
 *
 * @function conversationStatusChanged
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the message.
 * @param {...any} data - Additional data related to the message.
 * @returns {void}
 */
function conversationStatusChanged(store, data) {
  store.dispatch('updateConversation', data).then(r => r);
  fetchConversationStats();
}

/**
 * Handles a mention in an conversation by dispatching relevant Vuex actions.
 *
 * @function conversationAddMentioned
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the message.
 * @param {...any} data - Additional data related to the message.
 * @returns {void}
 */
function conversationAddMentioned(store, data) {
  store.dispatch('addMentions', data).then(r => r);
}

// Inbox
/// /////////////////////////////

/**
 * Handles the change on the inbox on an conversation by dispatching relevant Vuex actions.
 *
 * @function conversationCreated
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the message.
 * @param {...any} data - Additional data related to the message.
 * @returns {void}
 */
function inboxChanged(store, data) {
  store
    .dispatch('updateInboxOnConversations', {
      conversationIds: [data.id],
      inboxId: data.inbox_id,
    })
    .then(r => r);
  fetchConversationStats();
}

// User/Service Handler
/// /////////////////////////////
/**
 * Handles the user logout process by calling the authentication API.
 *
 * @function userLogout
 * @returns {void}
 */
function userLogout() {
  AuthAPI.logout();
}

/**
 * Reloads the current web page.
 *
 * @function reloadPage
 * @returns {void}
 */
function reloadPage() {
  window.location.reload();
}

/**
 * Updates the presence information for contacts and agents, and sets the availability of the current user.
 *
 * @function presenceUpdate
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for updating presence information.
 * @param {Array} data.contacts - An array of contact objects with presence information.
 * @param {Array} data.users - An array of user objects with presence information.
 * @returns {void}
 *
 * @todo 36826 Create a separate socket event for updating the agents' presence.
 */
function presenceUpdate(store, data) {
  // TODO: 36826 create a separate socket event for updating the agents presence
  store.dispatch('contacts/updatePresence', data.contacts).then(r => r);
  store.dispatch('agents/updatePresenceForAll', data.users).then(r => r);
  store.dispatch('setCurrentUserAvailability', data.users).then(r => r);
}

// Typing
/// ////////////////////////////
/**
 * Handles turning off the typing indicator by dispatching a Vuex action to update typing status.
 *
 * @function onTypingOff
 * @param {Object} store - The Vuex store instance.
 * @param {Object} params - Parameters containing conversation and user details.
 * @param {Object} params.conversation - The conversation object.
 * @param {number|string} params.conversation.id - The unique identifier of the conversation.
 * @param {Object} params.user - The user object.
 * @param {...any} params - Additional parameters.
 * @returns {void}
 */
function typingOn(store, { conversation, user }) {
  clearTimer(conversation.id);
  store
    .dispatch('conversationTypingStatus/create', {
      conversationId: conversation.id,
      user,
    })
    .then(r => r);
  initTimer({ conversation, user });
}

/**
 * Handles turning off the typing indicator by dispatching a Vuex action to update typing status.
 *
 * @function typingOff
 * @param {Object} store - The Vuex store instance.
 * @param {Object} params - Parameters containing conversation and user details.
 * @param {Object} params.conversation - The conversation object.
 * @param {number|string} params.conversation.id - The unique identifier of the conversation.
 * @param {Object} params.user - The user object.
 * @param {...any} params - Additional parameters.
 * @returns {void}
 */
function typingOff(store, { conversation, user }) {
  clearTimer(conversation.id);
  store
    .dispatch('conversationTypingStatus/destroy', {
      conversationId: conversation.id,
      user,
    })
    .then(r => r);
}

// Notification Handler
/// ////////////////////////////
/**
 * Handles the creation of a notification by dispatching a Vuex action to add the notification.
 *
 * @function notificationCreated
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the new notification.
 * @param {...any} data - Additional properties related to the notification.
 * @returns {void}
 */
function notificationCreated(store, data) {
  store.dispatch('notifications/addNotification', data).then(r => r);
}

// Contact Handler
// We dont use these
/// ///////////////////////////
/**
 * Handles the deletion of a contact by dispatching a Vuex action and updating conversation statistics.
 *
 * @function contactDelete
 * @deprecated
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the contact to be deleted.
 * @param {number|string} data.id - The unique identifier of the contact.
 * @returns {void}
 */
function contactDelete(store, data) {
  store
    .dispatch('contacts/deleteContactThroughConversations', data.id)
    .then(r => r);
  // ActionCableConnector.fetchConversationStats();
}

/**
 * Handles the update of a contact by dispatching a Vuex action.
 *
 * @function contactUpdate
 * @deprecated
 * @param {Object} store - The Vuex store instance.
 * @param {Object} data - The data for the contact to be updated.
 * @param {...any} data - Additional properties related to the contact.
 * @returns {void}
 */
function contactUpdate(store, data) {
  store.dispatch('contacts/updateContact', data).then(r => r);
}

/**
 * Events Handler
 *
 * @param {Vuex store} store
 * @returns {function(event): void} hondler function for the event streams
 */
export default function eventsHandler(store) {
  return event => {
    switch (event.event) {
      // Conversations
      /// /////////////////////////////
      case 'message.created':
        messageCreated(store, event.data);
        break;
      case 'message.updated':
        messageUpdated(store, event.data);
        break;
      case 'conversation.created':
        conversationCreated(store, event.data);
        break;
      case 'conversation.status_changed':
        conversationStatusChanged(store, event.data);
        break;
      case 'assignee.changed':
        conversationAssigneeChanged(store, event.data);
        break;
      case 'conversation.typing_on':
        typingOn(store, event.data);
        break;
      case 'conversation.typing_off':
        typingOff(store, event.data);
        break;
      case 'conversation.contact_changed':
        break;
      case 'conversation.mentioned':
        conversationAddMentioned(store, event.data);
        break;
      case 'conversation.read':
        conversationRead(store, event.data);
        break;
      case 'conversation.inbox_changed':
        inboxChanged(store, event.data);
        break;
      // Notifications
      /// //////////////////////////
      case 'notification.created':
        notificationCreated(store, event.data);
        break;
      // User/Service
      /// /////////////////////////
      case 'presence.update':
        presenceUpdate(store, event.data);
        break;
      case 'user:logout':
        userLogout();
        break;
      case 'page:reload':
        reloadPage();
        break;
      // Contacts
      /// /////////////////////////
      case 'contact.deleted':
        contactDelete(store, event.data);
        break;
      case 'contact.updated':
        contactUpdate(store, event.data);
        break;
      default:
    }
  };
}
